import React from 'react';
import styled from 'styled-components';

import Layout from '../../../components/Layout.jsx';
import SEO from '../../../components/Seo.jsx';
import DocumentationList from '../../../components/DocumentationList.jsx';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { lang: { eq: "fr" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default ({ data }) => (
  <Layout lang="fr">
    <p>
      Chaque étape de la création et de la gestion de votre horaire a sa propre page de
      documentation.
    </p>
    <DocumentationList docs={data.allMarkdownRemark.edges} />
  </Layout>
);
